import GATSBY_COMPILED_MDX from "/opt/buildhome/repo/content/pages/yritys.mdx";
import React from "react";
import {MDXProvider} from "@mdx-js/react";
import Layout from "../components/Layout";
import {Button, Container, Row, Col, Card} from "react-bootstrap";
import Hero from "../components/Hero";
import Seo from "../components/seo";
import {graphql, Link} from "gatsby";
import {useSiteMetadata} from "../hooks/useSiteMetadata";
import ButtonLink from "../components/ButtonLink";
import IconCard from "../components/IconCard";
import Circle from "../components/Circle";
const shortcodes = {
  Hero,
  Button,
  Container,
  Row,
  Col,
  Card,
  Link,
  ButtonLink,
  IconCard,
  Circle
};
const PageTemplate = ({children, data}) => {
  return React.createElement(React.Fragment, null, React.createElement(Layout, null, React.createElement(MDXProvider, {
    components: shortcodes,
    data: data
  }, children)));
};
PageTemplate
export default function GatsbyMDXWrapper(props) {
  return React.createElement(PageTemplate, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export const Head = ({data}) => {
  const meta = useSiteMetadata();
  return React.createElement(Seo, {
    title: `${meta.name} - ${data.mdx.frontmatter.title}`,
    description: data.mdx.frontmatter.description || meta.description
  });
};
export const query = graphql`
  query ($id: String!) {
    mdx(id: { eq: $id }) {
      frontmatter {
        title
        slug
        description
        date
        image
        tags
        localImages
      }
    }
  }
`;
